.carousel-container {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: contain;
}



  .mainTitle {
    color: #008080
  }

  .thicker-hr {
    border-top: 1.8px solid #008080; 
  }

  .video {
    max-width: 100%;
    max-height: 60%;
    border: none !important;
}

  .image {
    width: 100%;
    height: auto;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  
  