.etsy {
    background-color: #E9611B !important;
    border: #E9611B !important;
}

.images {
    max-width: 100%;
    height: 40vh;
    object-fit: contain
  }

  .category-title {
    color: #008080
  }