.artwork-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  
  .artwork-image {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
    text-align: center;
  }

  .marginb { 
    margin-bottom: 0.5rem;
  }

  .buttonText {
    font-size: small;
  }

  .ref {
    font-size: x-small;
  }

  .buy {
    color: #008080;
  }
  
  .artwork-price {
    font-weight: bold;
    font-size: small;
  }
  .artwork-shipping {
    font-weight: lighter;
    font-size: x-small;
  }

  .artwork-description {
    font-weight: lighter;
    font-size: medium;
  }

  .artwork-buttons {
    display: flex;
    justify-content: center;
  }
  
  .contact-button,
  .contact-etsy-button {
    margin: 0 5px;
  }