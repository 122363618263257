@font-face {
  font-family: 'Lato';
  src: url('font/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('font/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

p {
  font-family: 'Lato', sans-serif;
}

h1 {
  font-family: 'PlayfairDisplay', sans-serif;
}

h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
}

.nav-styles.active {
  color: #800080 !important;
}

a{
    text-decoration: none !important;
    color: black !important;
    font-family: 'Lato', sans-serif;
}

html, body {
    height: 100%;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .nav-logo {
    width: 8rem;
    height: 8rem;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .content {
    flex: 1;
  }

  .nav-styles {
    margin-right: 1rem;

  }
  
  .footer {
    margin-top: auto;
  }
  
  
